@font-face {
  font-family: 'Eina01';
  src: url('./Assets/Fonts/Eina01Regular.otf') format('opentype'),
    url('./Assets/Fonts/Eina01Regular.woff') format('woff'),
    url('./Assets/Fonts/Eina01Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Eina01';
  src: url('./Assets/Fonts/Eina01RegularItalic.otf') format('opentype'),
    url('./Assets/Fonts/Eina01RegularItalic.woff') format('woff'),
    url('./Assets/Fonts/Eina01RegularItalic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Eina01';
  src: url('./Assets/Fonts/Eina01Light.otf') format('opentype'),
    url('./Assets/Fonts/Eina01Light.woff') format('woff'),
    url('./Assets/Fonts/Eina01Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Eina01';
  src: url('./Assets/Fonts/Eina01LightItalic.otf') format('opentype'),
    url('./Assets/Fonts/Eina01LightItalic.woff') format('woff'),
    url('./Assets/Fonts/Eina01LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Eina01';
  src: url('./Assets/Fonts/Eina01SemiBold.otf') format('opentype'),
    url('./Assets/Fonts/Eina01SemiBold.woff') format('woff'),
    url('./Assets/Fonts/Eina01SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Eina01';
  src: url('./Assets/Fonts/Eina01SemiBoldItalic.otf') format('opentype'),
    url('./Assets/Fonts/Eina01SemiBoldItalic.woff') format('woff'),
    url('./Assets/Fonts/Eina01SemiBoldItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Eina01';
  src: url('./Assets/Fonts/Eina01Bold.otf') format('opentype'),
    url('./Assets/Fonts/Eina01Bold.woff') format('woff'),
    url('./Assets/Fonts/Eina01Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Eina01';
  src: url('./Assets/Fonts/Eina01BoldItalic.otf') format('opentype'),
    url('./Assets/Fonts/Eina01BoldItalic.woff') format('woff'),
    url('./Assets/Fonts/Eina01BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

* {
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: 600;
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.24px;
  color: #212124;
  font-family: 'Eina01', 'sans-serif';
  -webkit-font-smoothing: antialiased;
}

body.no-scroll {
  overflow: hidden;
}

img {
  max-width: 100%;
  display: block;
  height: var(--height);
}

input,
button,
textarea,
select {
  font: inherit;
  outline: none;
  appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  text-decoration: none;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slide-down-away {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes loader-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@keyframes slide-in-screen {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-out-screen {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounce-small {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slide-out-delete {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.datepicker {
  position: relative !important;
}

.datepicker-navbar {
  display: none;
}

.datepicker-header {
  display: none;
}

.datepicker.ios {
  background: #fff !important;
}

.datepicker.ios .datepicker-viewport::after {
  background: linear-gradient(
    #fff,
    rgba(245, 245, 245, 0) 52%,
    rgba(245, 245, 245, 0) 48%,
    #fff
  ) !important;
}

.datepicker.ios .datepicker-scroll li {
  color: #212124;
}

.datepicker-content .datepicker-col-1:first-child {
  flex: 3;
}

.datepicker-content .datepicker-col-1:not(:first-child) {
  flex: 1;
}

.datepicker-content .datepicker-col-1:not(:first-child) .datepicker-scroll {
  width: 30px;
}

.datepicker.ios .datepicker-content {
  padding-top: 0 !important;
}

.adyen-checkout__dropin {
  padding-top: 10px;
}

.adyen-checkout__button {
  background-color: black;
}

.adyen-checkout__button__text {
  font-weight: 600;
}

.adyen-checkout__button__icon {
  display: none;
}

.adyen-checkout__icon {
  display: none;
}
.adyen-checkout__threeds2__challenge--02,
.adyen-checkout__threeds2__challenge--02 .adyen-checkout__iframe--threeDSIframe {
  width: 100%;
}
@media (min-width: 768px) {
  .Toastify__toast-container--bottom-center {
    left: calc((100% - 303px) / 2);
    transform: translateX(50%);
  }
}
